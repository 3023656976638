import * as Sentry from '@sentry/sveltekit';

Sentry.init({
  dsn: 'https://03ac1902827083145415229f5fab0233@o4505042334777344.ingest.sentry.io/4506774426419200',

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,

  // Optional: Initialize Session Replay:
  integrations: [Sentry.replayIntegration()],
  attachStacktrace: true,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: process.env.NODE_ENV === 'production'
});

export const handleError = Sentry.handleErrorWithSentry();
