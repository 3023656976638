import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57')
];

export const server_loads = [2];

export const dictionary = {
		"/(web)": [5,[2]],
		"/(web)/contact-us": [30,[2]],
		"/(web)/glossary": [31,[2]],
		"/(web)/login": [32,[2]],
		"/(web)/logout": [33,[2]],
		"/(web)/map": [34,[2]],
		"/(web)/(authenticated)/my-portfolios": [7,[2]],
		"/(web)/(authenticated)/my-portfolios/add-properties": [10,[2]],
		"/(web)/(authenticated)/my-portfolios/add": [9,[2]],
		"/(web)/(authenticated)/my-portfolios/[id]": [8,[2]],
		"/(web)/(authenticated)/my-properties": [11,[2]],
		"/(web)/(authenticated)/my-properties/add": [~26,[2]],
		"/(web)/(authenticated)/my-properties/bulk-manage": [27,[2]],
		"/(web)/(authenticated)/my-properties/quick-add": [28,[2]],
		"/(web)/(authenticated)/my-properties/[id]": [~12,[2,3]],
		"/(web)/(authenticated)/my-properties/[id]/build-estimate": [13,[2,3]],
		"/(web)/(authenticated)/my-properties/[id]/details": [14,[2,3]],
		"/(web)/(authenticated)/my-properties/[id]/details/beyond-producing-wells": [15,[2,3]],
		"/(web)/(authenticated)/my-properties/[id]/details/lease-estimate": [16,[2,3]],
		"/(web)/(authenticated)/my-properties/[id]/details/monthly-revenue": [17,[2,3]],
		"/(web)/(authenticated)/my-properties/[id]/details/new-drilling": [18,[2,3]],
		"/(web)/(authenticated)/my-properties/[id]/details/new-permits": [19,[2,3]],
		"/(web)/(authenticated)/my-properties/[id]/details/new-wells": [20,[2,3]],
		"/(web)/(authenticated)/my-properties/[id]/details/sales-estimate": [21,[2,3]],
		"/(web)/(authenticated)/my-properties/[id]/details/well-information": [22,[2,3]],
		"/(web)/(authenticated)/my-properties/[id]/subscribe": [~23,[2,3]],
		"/(web)/(authenticated)/my-properties/[id]/subscribe/pay/success": [~24,[2,3]],
		"/(web)/(authenticated)/my-properties/[id]/well-interest": [~25,[2,3]],
		"/(web)/privacy-policy": [54,[2]],
		"/(web)/(authenticated)/profile": [~29,[2]],
		"/(web)/(authenticated)/(admin)/properties/[id]/update": [~6,[2]],
		"/(web)/p/[id]": [~35,[2,4]],
		"/(web)/p/[id]/details/beyond-producing-wells": [36,[2,4]],
		"/(web)/p/[id]/details/lease-estimate": [37,[2,4]],
		"/(web)/p/[id]/details/monthly-revenue": [38,[2,4]],
		"/(web)/p/[id]/details/new-drilling": [39,[2,4]],
		"/(web)/p/[id]/details/new-permits": [40,[2,4]],
		"/(web)/p/[id]/details/new-wells": [41,[2,4]],
		"/(web)/p/[id]/details/sales-estimate": [42,[2,4]],
		"/(web)/p/[id]/details/well-information": [43,[2,4]],
		"/(web)/p/[id]/print/dashboard": [~44,[2,4]],
		"/(web)/p/[id]/print/details/beyond-producing-wells": [45,[2,4]],
		"/(web)/p/[id]/print/details/lease-estimate": [46,[2,4]],
		"/(web)/p/[id]/print/details/monthly-revenue": [47,[2,4]],
		"/(web)/p/[id]/print/details/new-drilling": [48,[2,4]],
		"/(web)/p/[id]/print/details/new-permits": [49,[2,4]],
		"/(web)/p/[id]/print/details/new-wells": [50,[2,4]],
		"/(web)/p/[id]/print/details/sales-estimate": [51,[2,4]],
		"/(web)/p/[id]/print/details/well-information": [52,[2,4]],
		"/(web)/p/[id]/print/well-interest": [~53,[2,4]],
		"/(web)/reset-password": [55,[2]],
		"/(web)/signup": [56,[2]],
		"/(web)/terms-conditions": [57,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';